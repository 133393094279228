<template>
    <div class="roadFront">
        <div class="loading" v-if="loading" style="width: 183px;top: 29px;">
            <i class="d-center">
                <img src="@/assets/mapimages/new/map-loading.gif" alt />
            </i>
            <span>正在导出，请稍等！！！</span>
        </div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" >
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 165px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 165px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目单位">
                                        <el-input style="width: 165px" size="medium" v-model="XMCW" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
<!--                                    <el-form-item label="路线性质">-->
<!--                                        <el-cascader size="medium" style="width: 165px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>-->
<!--                                        </el-cascader>-->
<!--                                    </el-form-item>-->
<!--                                    <el-form-item label="建设性质">-->
<!--                                        <el-cascader size="medium" style="width: 165px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>-->
<!--                                        </el-cascader>-->
<!--                                    </el-form-item>-->
<!--                                    <el-form-item label="审批流程">-->
<!--                                        <el-cascader size="medium" style="width: 165px" v-model="checkedFlow" :options="flowList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>-->
<!--                                        </el-cascader>-->
<!--                                    </el-form-item>-->
                                    <!--                                    <el-form-item label="填报批次">-->
                                    <!--                                        <el-cascader-->
                                    <!--                                                v-model="treeVal"-->
                                    <!--                                                :options="treeData"-->
                                    <!--                                                :props="defaultParams"-->
                                    <!--                                                @change="changePc"-->
                                    <!--                                                clearable></el-cascader>-->
                                    <!--                                    </el-form-item>-->
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
<!--                                    <el-button @click="exportData(2)" type="primary" size="mini" round icon="el-icon-upload2">通过项目导出</el-button>-->
<!--                                    <span class="newre" v-if="isAdmin">-->
<!--                                        <el-cascader @change="changeAddressCity" style="width:122px;line-height: 28px;" collapse-tags size="medium" v-model="expParamsCity" :options="regions2" :props="{ multiple: true}" clearable ></el-cascader>-->
<!--                                        <el-button @click="exportData(3)" type="primary" size="mini" round >分地市导出</el-button>-->
<!--                                    </span>-->
                                    <el-button @click="exportData(1)" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="getWarning()" type="primary" size="mini" round icon="el-icon-thumb">督导台</el-button>
                                    <!--                                    <el-button @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">填报</el-button>-->
                                </span>
                                <div style="display: flex">
                                    <h3 class="tit">项目列表</h3>
                                </div>
                            </div>
                            <div class="box-content" style="padding-top: 0;">
                                    <div style="margin-top: 10px">
                                        <el-table  ref="table" :data="tableData"  default-expand-all row-key="Id" :tree-props="{children: 'Children', hasChildren: 'hasChildren'}" size="small " :height="tableHeight" border  style="width: 100%">
<!--                                            <el-table-column-->
<!--                                                    type="selection"-->
<!--                                                    width="55">-->
<!--                                            </el-table-column>-->
<!--                                            <el-table-column fixed prop="index"  label="序号"  align="center" type="index" width="55" :index="1+30*(currentPage-1)"></el-table-column>-->
                                            <el-table-column fixed prop="" align="center" :width="50" label="序号">
                                                <template slot-scope="scope">
                                                 <span>{{scope.row.XH}}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column fixed prop="" align="center" :width="180" label="操作">
                                                <template slot-scope="scope">
                                                    <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
                                                    <span class="newicon iconsyes" v-if="scope.row.Pid == null" @click="addRoad(scope.row)"><i class="el-icon-circle-plus" ></i>添加</span>
                                                    <span class="newicon iconsyes" style="color:#999;width: 100px" v-if="scope.row.Pid != null" @click="deleted(scope.row)" ><i class="el-icon-remove"></i>删除</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="XMDM"  align="center" label="项目代码" :width="220" show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="XMSZD"  label="项目所在地" :width="100"  show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="ZGBM"  label="主管部门" :width="100"  show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="SSTXLY"  label="所属投向领域" :width="150"  show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="XMMC" label="项目名称" align="left" show-overflow-tooltip :width="340"></el-table-column>
                                            <el-table-column prop="XMCW" label="项目单位" align="left" show-overflow-tooltip :width="150"></el-table-column>
                                            <el-table-column prop="KGHYJKGSJ" label="开工或预计开工时间" align="left" show-overflow-tooltip :width="150"></el-table-column>
                                            <el-table-column prop="YJWGSJ" label="预计完工时间" align="left" show-overflow-tooltip :width="150"></el-table-column>
                                            <el-table-column prop="ZTZ" label="总投资" align="left" show-overflow-tooltip :width="150"></el-table-column>
                                            <el-table-column prop="YDWZJZE" label="已到位资金总额" align="left" show-overflow-tooltip :width="150"></el-table-column>
                                        </el-table>
<!--                                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>-->
                                    </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <!--            详情弹窗-->
            <el-drawer :visible.sync="showAddModel" @close="closeDrawer" :before-close="closeDrawer2" size="1300px" :title="XMMC"  class="editModal">
                <el-form :disabled="!isLock"  :model="detail"  ref="detailForm"  class="form-inline" v-loading="uploadLoading">
                    <el-tabs v-model="activeName" type="card">
                        <el-tab-pane label="基本信息" name="0">
                            <div style="overflow: auto;height: calc(100vh - 130px);">
                                <div class="col" >
                                    <div class="col-1">
                                        <el-divider>基本信息</el-divider>
                                        <el-form-item  label="项目代码" prop="XMDM" >
                                            <el-input disabled v-model="detail.XMDM" ></el-input>
                                        </el-form-item>
                                        <el-form-item  label="项目名称" prop="XMMC" :rules="[{ required: true, message:'请输入项目名称',trigger: 'blur' }]">
                                            <el-input  v-model="detail.XMMC" ></el-input>
                                        </el-form-item>
                                        <el-form-item v-if="detail.Pid != null" label="项目类别"  prop="SJLX" :rules="[{ required: true, message:'请选择项目类别',trigger: 'change' }]">
                                            <el-select  @change="changeXLXZ" placeholder="请选择项目类别" v-model="detail.SJLX" >
                                                <el-option label="公路" :value=0></el-option>
                                                <el-option label="桥梁" :value=1></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item  label="项目所在地"  prop="XMSZD" >
                                                <el-input  disabled v-model="detail.XMSZD" ></el-input>
                                        </el-form-item>
                                        <el-form-item  label="项目单位"  prop="XMCW" >
                                            <el-input disabled v-model="detail.XMCW" ></el-input>
                                        </el-form-item>
                                        <el-form-item  label="主管部门"  prop="ZGBM" >
                                            <el-input disabled v-model="detail.ZGBM" ></el-input>
                                        </el-form-item>
                                        <el-form-item  label="所属投向领域"  prop="SSTXLY" >
                                            <el-input disabled v-model="detail.SSTXLY" ></el-input>
                                        </el-form-item>
                                        <el-form-item label='主要建设内容'>
                                            <el-input autosize rows="4"  placeholder="请输入备注" type="textarea" v-model="detail.ZYJSNR"></el-input>
                                        </el-form-item>
                                        <!-- :rules="[{ required: true, message:'请选择项目类别',trigger: 'change' }]" -->
                                    </div>
<!--                                    桥梁信息-->
                                        <div class="col-1" v-if="detail.SJLX == '1'">
                                            <el-divider>桥梁信息</el-divider>
                                            <el-form-item label="2022年报桥梁编码" :rules="[{ required: true, message:'请填写2022年报桥梁编码',trigger: 'blur' }]" prop="NBQLBM" >
                                                <el-input  placeholder="2022年报桥梁编码" v-model="detail.NBQLBM" ></el-input>
                                            </el-form-item>
                                            <el-form-item label="2022年报桥梁名称" :rules="[{ required: true, message:'请填写2022年报桥梁名称',trigger: 'blur' }]" prop="NBQLMC" >
                                                <el-input  placeholder="2022年报桥梁名称" v-model="detail.NBQLMC" ></el-input>
                                            </el-form-item>
                                            <el-form-item label="2022年报桥梁中心桩号" :rules="[{ required: true, message:'请填写2022年报桥梁名称',trigger: 'blur' }]" prop="NBQLZXZH" >
                                                <el-input  placeholder="2022年报桥梁中心桩号" v-model="detail.NBQLZXZH" ></el-input>
                                            </el-form-item>
                                            <el-divider>原桥规模</el-divider>
                                            <el-form-item label="桥梁全长"  prop="QLQC">
                                                <el-input placeholder="桥梁全长" @input="changeJstotal($event,'gs')" v-model="detail.QLQC"><template #suffix>延米</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="桥梁全宽"  prop="QLQK">
                                                <el-input placeholder="桥梁全长" @input="changeJstotal($event,'gs')" v-model="detail.QLQK"><template #suffix>延米</template></el-input>
                                            </el-form-item>
                                            <el-divider>拟建规模</el-divider>
                                            <el-form-item label="桥梁全长"  prop="QLQC">
                                                <el-input placeholder="桥梁全长" @input="changeJstotal($event,'gs')" v-model="detail.NJQLQC"><template #suffix>延米</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="桥梁全宽"  prop="QLQK">
                                                <el-input placeholder="桥梁全长" @input="changeJstotal($event,'gs')" v-model="detail.NJQLQK"><template #suffix>延米</template></el-input>
                                            </el-form-item>
                                        </div>
                                        <!--                                    公路信息-->
                                        <div class="col-1" v-if="detail.SJLX == '0'">
                                            <el-divider>项目建设位置</el-divider>
                                            <el-form-item label="2022年报路线编码" :rules="[{ required: true, message:'2022年报路线编码',trigger: 'blur' }]" prop="LXBM" >
                                                <el-input  placeholder="2022年报路线编码" v-model="detail.LXBM" ></el-input>
                                            </el-form-item>
                                            <el-form-item label="2022年报路线名称" :rules="[{ required: true, message:'2022年报路线名称',trigger: 'blur' }]" prop="LXMC" >
                                                <el-input  placeholder="2022年报路线名称" v-model="detail.LXMC" ></el-input>
                                            </el-form-item>
                                            <el-form-item label="起点桩号" :rules="[{ required: true, message:'起点桩号',trigger: 'blur' }]" prop="QDZH" >
                                                <el-input  placeholder="起点桩号" v-model="detail.QDZH" ></el-input>
                                            </el-form-item>
                                            <el-form-item label="讫点桩号" :rules="[{ required: true, message:'讫点桩号',trigger: 'blur' }]" prop="QDZH1" >
                                                <el-input  placeholder="讫点桩号" v-model="detail.QDZH1" ></el-input>
                                            </el-form-item>
                                            <el-divider>公路信息</el-divider>
                                            <el-form-item label="合计"  prop="JSGMGJ" >
                                                <el-input placeholder="合计"  v-model="detail.JSGMGJ"></el-input>
                                            </el-form-item>
                                            <el-form-item label="二级"  prop="EJ">
                                                <el-input placeholder="二级" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.EJ"></el-input>
                                            </el-form-item>
                                            <el-form-item label="三级" prop="SJ">
                                                <el-input placeholder="三级" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.SJ"></el-input>
                                            </el-form-item>
                                            <el-form-item label="四级"  prop="SI">
                                                <el-input placeholder="四级" :disabled="!isEdit" @input="changeJstotal($event,'gs')" v-model="detail.SI"></el-input>
                                            </el-form-item>
                                            <el-form-item label="沥青混凝土路面"  prop="LQHNTLM">
                                                <el-input placeholder="沥青混凝土路面"   v-model="detail.LQHNTLM"></el-input>
                                            </el-form-item>
                                            <el-form-item label="水泥混凝土路面"  prop="SNHNTLM">
                                                <el-input placeholder="水泥混凝土路面"  v-model="detail.SNHNTLM"></el-input>
                                            </el-form-item>

                                        </div>

                                    <div class="col-1">
                                        <el-divider>前期工作情况</el-divider>
                                        <el-form-item label="可研批复文号"  prop="KYPFWH" >
                                            <el-input placeholder="可研批复文号"  v-model="detail.KYPFWH"></el-input>
                                        </el-form-item>
                                        <el-form-item label="施工图批复文号"  prop="SGTPFWH">
                                            <el-input placeholder="施工图批复文号"  v-model="detail.SGTPFWH"></el-input>
                                        </el-form-item>
                                        <el-form-item label="用地审批文号"  prop="YDSPWH">
                                            <el-input placeholder="用地审批文号"  v-model="detail.YDSPWH"></el-input>
                                        </el-form-item>
                                        <el-form-item label="环评审批文号"  prop="HPSPWH">
                                            <el-input placeholder="环评审批文号"  v-model="detail.HPSPWH"></el-input>
                                        </el-form-item>
                                        <el-form-item label="施工许可文号"  prop="SGHKWH">
                                            <el-input placeholder="施工许可文号"  v-model="detail.SGHKWH"></el-input>
                                        </el-form-item>
                                        <el-form-item label="开工或预计开工时间" >
                                            <el-date-picker    style="width: 330px"
                                                            format="yyyy-MM-dd"
                                                            value-format="yyyy-MM-dd"
                                                            v-model="detail.KGHYJKGSJ" type="date" placeholder="选择日期">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="预计完工时间" >
                                            <el-date-picker   style="width: 330px"
                                                            format="yyyy-MM-dd"
                                                            value-format="yyyy-MM-dd"
                                                            v-model="detail.YJWGSJ" type="date" placeholder="选择日期">
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>
                                </div>

                            </div>
                        </el-tab-pane>
                        <el-tab-pane  label="资金计划（万元）" name="2">
                            <div style="overflow: auto;height: calc(100vh - 180px);">
                                <table border style="text-align:center;margin-top: 20px;width:100%"  class="littleTable">
                                    <tr>
                                        <th colspan="7"  style="background: #f5f7fa;">
                                            <div class="label2" >项目资金构成</div>
                                        </th>
                                        <th colspan="1" rowspan="3" style="background: #f5f7fa;">
                                            <div class="label2" >其他资金需备注明细</div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colspan="1" rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >总投资</div>
                                        </th>
                                        <th colspan="1"  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >增发国债资金需求</div>
                                        </th>
                                        <th colspan="1"  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >中央预算内投资</div>
                                        </th>
                                        <th colspan="1"  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" style="line-height: 30px">其他中央财政性建设资金 <br>（下达中央车购税）</div>
                                        </th>
                                        <th colspan="1"  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >地方财政自有财力资金</div>
                                        </th>
                                        <th colspan="2"  rowspan="1" style="background: #f5f7fa;">
                                            <div class="label2" >拟申请一般债券支持额度</div>
                                        </th>
                                    </tr>
                                    <tr>

                                        <th colspan="1" style="background: #f5f7fa;">
                                            <div class="label2" >省级留用</div>
                                        </th>
                                        <th colspan="1" style="background: #f5f7fa;">
                                            <div class="label2" >转贷市县</div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="ZTZ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.ZTZ" placeholder="总投资"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="ZJGCZFGZZJXQ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.ZJGCZFGZZJXQ" placeholder="增发国债资金需求"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="ZJGCZYYSNTZ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.ZJGCZYYSNTZ" placeholder="中央预算内投资"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="ZJGCJTZYCZXJSZJ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.ZJGCJTZYCZXJSZJ" placeholder="其他中央财政性建设资金（下达中央车购税）"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="ZJGCDFCZZYCLZJ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.ZJGCDFCZZYCLZJ" placeholder="地方财政自有财力资金"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="ZJGCSJLY" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.ZJGCSJLY" placeholder="省级留用"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="ZJGCZDSX" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.ZJGCZDSX" placeholder="转贷市县"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="ZJGCJTZJXBZMX" >
                                                <el-input  size="medium"    class="inputright" v-model="detail.ZJGCJTZJXBZMX" placeholder="其他资金需备注明细"></el-input>
                                            </el-form-item>
                                        </th>
                                    </tr>
                                </table>


                                <table border style="text-align:center;margin-top: 20px;width:100%"  class="littleTable">
                                    <tr>
                                        <th colspan="7"  style="background: #f5f7fa;">
                                            <div class="label2" >项目总投资已到位资金</div>
                                        </th>
                                        <th colspan="1" rowspan="3" style="background: #f5f7fa;">
                                            <div class="label2" >已下达资金文件名称</div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colspan="1" rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >已到位资金总额</div>
                                        </th>
                                        <th colspan="1"  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >增发国债已下达资金</div>
                                        </th>
                                        <th colspan="1"  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >中央预算内投资</div>
                                        </th>
                                        <th colspan="1"  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" style="line-height: 30px">其他中央财政性建设资金<br>（下达中央车购税）</div>
                                        </th>
                                        <th colspan="1"  rowspan="2" style="background: #f5f7fa;">
                                            <div class="label2" >地方财政自有财力资金</div>
                                        </th>
                                        <th colspan="2"  rowspan="1" style="background: #f5f7fa;">
                                            <div class="label2" >一般债券支持额度</div>
                                        </th>
                                    </tr>
                                    <tr>

                                        <th colspan="1" style="background: #f5f7fa;">
                                            <div class="label2" >省级留用</div>
                                        </th>
                                        <th colspan="1" style="background: #f5f7fa;">
                                            <div class="label2" >转贷市县</div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="YDWZJZE" >
                                                <el-input  size="medium"  type="number"   class="inputright" v-model="detail.YDWZJZE" placeholder="已到位资金总额"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="YDWZJZFGZYXDZJ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.YDWZJZFGZYXDZJ" placeholder="增发国债已下达资金"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="YDWZJZYYSNTZ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.YDWZJZYYSNTZ" placeholder="中央预算内投资"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="YDWZJJTZYCZXJSZJ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.YDWZJJTZYCZXJSZJ" placeholder="其他中央财政性建设资金（下达中央车购税）"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="YDWZJDFCZZYCLZJ" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.YDWZJDFCZZYCLZJ" placeholder="地方财政自有财力资金"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="YDWZJSJLY" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.YDWZJSJLY" placeholder="省级留用"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="YDWZJZDSX" >
                                                <el-input  size="medium"  type="number"  class="inputright" v-model="detail.YDWZJZDSX" placeholder="转贷市县"></el-input>
                                            </el-form-item>
                                        </th>
                                        <th colspan="1"  style="background: #f5f7fa;">
                                            <el-form-item prop="YXDZJWJMC" >
                                                <el-input  size="medium"   class="inputright" v-model="detail.YXDZJWJMC" placeholder="已下达资金文件名称"></el-input>
                                            </el-form-item>
                                        </th>
                                    </tr>

                                </table>

                            </div>
                        </el-tab-pane>
                    </el-tabs>


                    <div class="footer">
                        <!-- :loading="buttonLoading" -->
                        <el-button type="primary" @click="save" >保存</el-button>
                        <el-button @click="cancel">取消</el-button>
                    </div>
                </el-form>
            </el-drawer>

        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span v-if="fillStatus">取消后需重新上报历史记录,是否确定退出?</span>
            <span v-else>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>
        <!--        审核窗口-->
        <el-dialog
                :visible.sync="showCheck"
                title="审核"
                width="30%"
        >
            <!-- <div class="cont" style="text-align: left">实际下达资金(万元):</div> -->
            <!-- <el-input
                     type="number"
                    placeholder="请输入内容"
                    v-model="xdje">
            </el-input> -->
            <div class="cont" style="text-align: left">备注:</div>
            <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                    v-model="remarks">
            </el-input>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="checkProject(false)">拒绝</el-button>
        <el-button type="primary" @click="checkProject(true)">通过</el-button>
      </span>
            </template>
        </el-dialog>
<!--        督导台-->
        <el-drawer :visible.sync="showWarningDialog" title="督导台" size="600px" :inline="true">
            <template>
                <el-table :data="warningData" style="width: 100%">
                    <el-table-column prop="XMMC" label="项目名称" width="300"></el-table-column>
                    <el-table-column prop="ZTZ" label="总投资差额" align="center">
                        <template slot-scope="scope">
                            <div style="color: #F54A26">{{scope.row.ZTZ}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="YDWZJZE" label="已到位资金差额" align="center"></el-table-column>
                </el-table>
            </template>
        </el-drawer>



        <el-dialog
                title="提示"
                :visible.sync="dialogVisible123"
                width="30%">
            <span>是否确认上报项目？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible123 = false">取 消</el-button>
                <el-button type="primary" @click="checksBs();dialogVisible123 = false">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    import http from "../../api/http";
    import { BigNumber } from 'bignumber.js';

    export default {
        name: "countrySide",
        data() {
            return {
                warningData:[],
                showWarningDialog: false,
                visible:false,
                show3:false,
                dialogVisible123:false,
                dialogVisibleid:"",
                shsbloading:true,
                tjcount:0,
                buttonLoading:true,
                expParamsCity:[],
                XMFRDW:'',
                XMFRDWID:'',
                activeRoad: '高速公路',
                xdje:'',
                frCompanyList:[{WCTZHJ: '', WCZYCGS: '',}],
                remarks:'',
                showCheck: false,
                isLock: true,
                isEdit:true,
                assign: false,
                formInline:'',
                activeName:'0',
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                required: false,
                title:'新增建设单位',
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                loading:false,
                detail:{},
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                // baseUrl: "http://192.168.0.112:9991/",
                XMMC:'',
                addStatus: true,
                tableHeight:0, //表格高度
                /**查询条件 begin*/
                xmmc: "", //项目名称
                XMCW:'',
                region: [""], //已选中行政区划
                /**查询条件 end*/
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                regions2: [
                    {
                        value: "",
                        label: "黑龙江省",
                        children:[],
                    },
                ],
                tableData: [], //请求列表
                total: 0, //请求记录数
                searchAll:[],
                newAdd:false,
                showEdit:false,
                isAdmin: false,
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
                companyData:[],
                total1:0,
                currentPage:1,
                pageSize:20,
                ssxzc:true,
                props: { multiple: true },
                defaultParams: {
                    value: "value", //设置默认值value
                    label: "label", //设置显示的是什么
                    children: "children", //设置子元素数组是啥
                    multiple: false,//多选
                },
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                fillStatus:false
            };
        },
        mounted() {
            var that = this
            // this.nowYear = new Date().getFullYear()
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height -140;
            }, 100);
            this.getRegion();
            this.search();

            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
            })
            // this.getCompanyList()
            // this.getFRDW()
        },
        activated(){
            console.log('进来了')
            if (localStorage.getItem('fillInfo')){
                console.log('有东西的')
                this.fillStatus = true
                this.addRoad()
                this.detail = JSON.parse(localStorage.getItem('fillInfo'))
                console.log(this.detail)
            }
        },
        computed:{

        },
        methods: {
            warningClassName(row,index){
                // if (row.row.cqsj === 0){
                //     return 'warning-row'
                // }else{
                //     return 'danger-row'
                // }
            },
            getWarning(){
                this.http.post('/api/Plan_disaster/ProgressReminder',{}).then(res=>{
                    console.log(res)
                    this.showWarningDialog = true
                    this.warningData = res
                    // var a = 0
                    // var b = 0
                    // var c = []
                    // var d  =[]
                    // res.data.map((item,index)=>{
                    //     c.push(item.frdw)
                    //     item.cqjd.map((subitem,subIndex)=>{
                    //         if (subitem.cqsj == 0){
                    //             a++
                    //         }else{
                    //             b++
                    //         }
                    //     })
                    //     this.activeNames.push(index)
                    // })
                    // c = new Set(c)
                    // d = new Set(d)
                    // console.log(c)
                    // this.cqsl = b
                    // this.lqsl = a
                    // this.yjdw = c
                    // if(this.cqsl != 0 || this.lqsl != 0){
                    //     this.showWarningDialog = true
                    // }
                })
            },
            add(a, b) {
                a = BigNumber(a);
                b = BigNumber(b);
                return a.plus(b).toNumber();   //结果需要用toNumber转为普通数字
            },
            minus(a, b) {
                a = BigNumber(a);
                b = BigNumber(b);
                return a.minus(b).toNumber();
            },
            multiAdd(...params) {
                let data = BigNumber(0);
                for (let index = 0; index < params.length; index++) {
                    const element = BigNumber(params[index]);
                    data = data.plus(element);
                }
                return data.toNumber();
            },

            //调用

            changePc(val){
                console.log(val)
                // var a = []
                // val.map((item,index)=>{
                //     a.push(item[1])
                // })
                // this.PCyear = [...new Set(a)]
                this.PCyear =val
            },
            handleNodeClick(data1, data2, data3) {
                console.log(data1); //传递给 data 属性的数组中该节点所对应的对象
                // console.log(data2);//节点对应的 Node
                // console.log(data3);//节点组件本身
                /*
                getCurrentKey 	获取当前被选中节点的 key，使用此方法必须设置 node-key 属性，若没有节点被选中则返回 null
                */
                console.log(this.$refs.tree.getCurrentKey());
            },
            handleCheckChange(nodeObj, SelectedObj) {
                console.log(nodeObj, SelectedObj)
                // console.log(this.$refs.tree.getCheckedNodes(true,false),'node');
                // console.log(this.$refs.tree.getCheckedKeys(false),'key');
                // console.log(this.$refs.tree.getCurrentKey());
                // console.log(this.$refs.tree.getHalfCheckedKeys());

                // if(checked){
                //     console.log(data)
                // }
            },
            changeXLXZ(index){
                console.log(index)
                this.detail.SJLX = index
            },
            setDSQX(row){
                if(row.SZS==row.SZX || !row.SZX){
                    return row.SZS
                }
                return row.SZS+'/'+row.SZX
            },
            getPc(){
                this.http.post('/api/Sys_Dictionary/GetVueDictionary',['jhtb_pc']).then(res=>{
                    // console.log(res);
                    if(res.length>0){
                        this.jhpc = res[0].data[0].value
                    }
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            tableRowClassName(row, index) {
                return this.checkSelectable1(row.row) ? "" : "hidden";
            },
            conformXmlxr(){
                if (this.chekfr.length != 0){

                    this.confirmXMFR =  this.chekfr
                    this.XMFRDW = this.confirmXMFR[0].DWMC
                    this.XMFRDWID = this.confirmXMFR[0].ID
                }else{
                    this.confirmXMFR=[{XMZRR:'',XMZRRLXDH:''}],

                        this.XMFRDW = ''
                    this.XMFRDWID = ''
                }
                this.frCompany = false
            },
            checkSelectable1(row) {
                if(!row.XMLXR){
                    row.XMLXR = ''
                }
                if (this.frForm.dwmc && row.DWMC.indexOf(this.frForm.dwmc) < 0 ||
                    this.frForm.xmzrr && row.XMZRR.indexOf(this.frForm.xmzrr) < 0 ||
                    this.frForm.xmlxr && row.XMLXR.indexOf(this.frForm.xmlxr) < 0 ) {
                    return false;
                } else {
                    return true;
                }
            },
            async selectCompany1(val){
                if(val.length > 1){
                    this.$refs.multipleTable1.clearSelection()
                    this.$refs.multipleTable1.toggleRowSelection(val.pop())
                }else{
                    this.chekfr = val;
                }
            },
            getFRDW(){
                var that =this
                this.frCompanyLists = []
                this.http.post('/api/Plan_buildcompany/GetPageData', {rows:1000}).then(res=>{
                    // console.log(res)
                    res.rows.map((item,index)=>{
                        // if (item.XMLX == 'Road'){
                        this.frCompanyLists.push(item)
                        // }
                    })

                    if(this.addStatus){
                        if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                            this.$nextTick(()=>{
                                this.$refs.multipleTable1.clearSelection()
                            })
                        }

                    }else {
                        if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                            setTimeout(()=> {
                                this.$nextTick(() => {
                                    that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                        if (item.ID == that.XMFRDWID) {
                                            return item
                                        }
                                    }))
                                }, 1000)
                            })
                        }
                    }
                })
                // console.log(this.frCompanyLists)
            },
            showFr(){
                var that = this
                // if(!this.isAdmin){
                //     this.$message.warning('暂无此权限')
                //     return false
                // }
                this.getFRDW()
                this.frCompany = true
                if(this.addStatus){
                    if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                        this.$nextTick(()=>{
                            this.$refs.multipleTable1.clearSelection()
                        })
                    }else{
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            })
                        },1000)

                    }

                }else {
                    if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyLists.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            }, 1000)
                        })
                    }
                }
            },
            changeRoad(tab, event){
                this.currentPage = 1
                this.checkedXmlxXMLB = [tab.name]
                this.search()
            },
            //所在市去重
            unique(arr) {
                return arr;
                // var a = JSON.parse(arr)
                // console.log(a)
                // var city = []
                // a.map((item)=>{
                //     city.push(item[0])
                // })
                // city =  Array.from(new Set(city))
                // return city =  city.toString()
                // // return arr.filter((arr) => !res.has(arr.projectNo) && res.set(arr.projectNo, 1));
            },
            unique1(arr){
                return arr;
                // var a = JSON.parse(arr)
                // var city = []
                // a.map((item)=>{
                //     city.push(item[1])
                // })
                // return city =  city.toString()
            },
            changeAddress(val){
                console.log(val)
            },
            changeAddressCity(val){
                console.log(val);
            },
            checkPro(row){
                this.showCheck = true
                this.id = row.Id
                // console.log(this.id)
            },
            checkProject(val){
                if(this.shsbloading == false){
                    return false;
                }

                console.log(val)
                if (val == false && this.remarks == ''){
                    this.$message.error('请输入拒绝原因')
                    return false
                }
                this.shsbloading = false;
                this.http.post('/api/Plan_filling/AuditFilling?id='+this.id+'&isPassed='+val+'&remarks='+this.remarks,{id:this.id,remarks:this.remarks,isPassed:val,xdje:this.xdje}).then(res=>{
                    this.shsbloading = true;
                    if(res.status){
                        this.$message.success(res.message)
                        this.remarks = ''
                        this.showCheck = false
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            checkSelectable(row) {
                if (this.formInline.dwmc && row.dwmc.indexOf(this.formInline.dwmc) < 0) {
                    return false;
                } else {
                    return true;
                }
            },
            //选择建设单位
            selectCompany(val){
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;

                }
            },
            searchCompanyList(){
                this.getCompanyList(1)
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                // console.log(this.confirmSSW,123);

                if (this.chek.length != 0 ){
                    this.getItemByQQID(this.confirmSSW);
                    this.XMMC = this.chek[0].XMMC
                    this.SSWSSWGHXMID =this.chek[0].SSWGHXMID
                    this.SSWGHXMMC =this.chek[0].SSWGHXMMC
                    this.QQID =this.chek[0].ID
                    this.detail.SSWXMMC = this.chek[0].SSWGHXMMC
                    this.detail.SSWXMID = this.chek[0].SSWGHXMID
                }else{
                    this.XMMC = ''
                    this.SSWSSWGHXMID = ''
                    this.SSWGHXMMC = ''
                    this.QQID = ''
                    this.detail.SSWXMMC = ""
                    this.detail.LJWCTZHJ = '';//添加累计完成投资
                    this.detail.LJWCTZZYTZ='';
                    this.ljxdtzlist = [];
                }
                this.assign = false
            },
            getItemByQQID(row){//根据前期ID获取历年计划
                console.log(123123123123);
                if(row.length>0){
                    var id = row[0].ID;
                    if(id){
                        // var postData = {
                        //     page: 1,
                        //     rows: 1000,
                        //     Sort: "XH",
                        //     Order: "desc",
                        //     wheres: JSON.stringify([]),
                        // };
                        // Plan_invest

                        this.http.post('/api/Plan_filling_history/GetItemByQQID?id='+id, {}).then(res=> {
                            if(res.length>0){
                                let lastdata = res[res.length-1]
                                // this.detail.LJWCTZHJ = lastdata.LJWCTZ;//添加累计完成投资
                                // this.detail.LJWCTZZYTZ=lastdata.LJWCZYCGS;
                                this.detail.LJWCTZCPYSF = lastdata.LJWCTZCPYSF
                                this.detail.LJWCTZCZZJ = lastdata.LJWCTZCZZJ
                                this.detail.LJWCTZDFZC = lastdata.LJWCTZDFZC
                                this.detail.LJWCTZHJ = lastdata.LJWCTZHJ
                                this.detail.LJWCTZJSNR = lastdata.LJWCTZJSNR
                                this.detail.LJWCTZQYZC = lastdata.LJWCTZQYZC
                                this.detail.LJWCTZXZSCNL = lastdata.LJWCTZXZSCNL
                                this.detail.LJWCTZYHDK = lastdata.LJWCTZYHDK
                                this.detail.LJWCTZZXZQ = lastdata.LJWCTZZXZQ
                                this.detail.LJWCTZZYTZ = lastdata.LJWCTZZYTZ
                                this.setZjValue(res)
                            }
                            this.ljxdtzlist = this.sortByKey(res,"XH",'asc')
                            console.log(this.ljxdtzlist.length,'this.ljxdtzlist')
                            if(this.ljxdtzlist.length >0){
                                this.showAllTZ = false
                            }else{
                                this.showAllTZ = true
                            }
                        })
                    }
                }
            },
            setZjValue(res){
                var hj = 0;
                var cgs = 0;
                var cz = 0;
                var cpy = 0;
                var zx = 0;
                var yh = 0;
                var qy = 0;
                var df = 0;
                var zc = 0;
                var sb = 0;
                res.map(r=>{
                    hj+=this.moneyToNumFiled(r.NJHTZHJ||0);
                    cgs+=this.moneyToNumFiled(r.NJHTZZYTZ||0);
                    cz+=this.moneyToNumFiled(r.NJHTZCZZJ||0);
                    cpy+=this.moneyToNumFiled(r.NJHTZCPYSF||0);
                    zx+=this.moneyToNumFiled(r.NJHTZZXZQ||0);
                    yh+=this.moneyToNumFiled(r.NJHTZYHDK||0);
                    qy+=this.moneyToNumFiled(r.NJHTZZQYZC||0);
                    df+=this.moneyToNumFiled(r.NJHTZDFZC||0);
                    zc+=this.moneyToNumFiled(r.NJHTZSSXZC||0);
                    sb+=this.moneyToNumFiled(r.NJHTZSJBZZZ||0);
                })

                this.ljxdhj.NJHTZHJ = hj;
                this.ljxdhj.NJHTZZYTZ = cgs;
                this.ljxdhj.NJHTZCZZJ = cz;
                this.ljxdhj.NJHTZCPYSF = cpy;
                this.ljxdhj.NJHTZZXZQ = zx;
                this.ljxdhj.NJHTZSSXZC = zc;
                this.ljxdhj.NJHTZYHDK = yh;
                this.ljxdhj.NJHTZZQYZC = qy;
                this.ljxdhj.NJHTZDFZC = df;
                this.ljxdhj.NJHTZSJBZZJ = sb;
                console.log(this.detail.JHZZJSSXZC,zc,this.detail.NJHTZSSXZC,'嘎嘎嘎嘎')
                this.detail.SYZJHJ = (parseFloat(this.detail.JHZZJHJ||0)-hj-parseFloat(this.detail.NJHTZHJ||0)).toFixed(2);
                this.detail.SYZJZYTZ =  this.setNumDefault(this.detail.JHZZJZYTZ)-cgs-this.setNumDefault(this.detail.NJHTZZYTZ);
                this.detail.SYZJCZZJ = this.setNumDefault(this.detail.JHZZJCZZJ)-cz-this.setNumDefault(this.detail.NJHTZCZZJ);
                this.detail.SYZJCPYSF = this.setNumDefault(this.detail.JHZZJCPYSF)-cpy-this.setNumDefault(this.detail.NJHTZCPYSF);
                this.detail.SYZJZXZQ = this.setNumDefault(this.detail.JHZZJZXZQ)-zx-this.setNumDefault(this.detail.NJHTZZXZQ);
                this.detail.SYZJSSXZC =this.setNumDefault(this.detail.JHZZJSSXZC)-zc-this.setNumDefault(this.detail.NJHTZSSXZC);
                this.detail.SYZJYHDK = this.setNumDefault(this.detail.JHZZJYHDK)-yh-this.setNumDefault(this.detail.NJHTZYHDK);
                this.detail.SYZJZQYZC = this.setNumDefault(this.detail.JHZZJQYZC)-qy-this.setNumDefault(this.detail.NJHTZZQYZC);
                this.detail.SYZJDFZC = (this.setNumDefault(this.detail.JHZZJDFZC)-df-this.setNumDefault(this.detail.NJHTZDFZC)).toFixed(2);
                this.detail.SYZJSJBZZJ = this.setNumDefault(this.detail.SJBZZJ)-sb-this.setNumDefault(this.detail.NJHTZSJBZZJ);

                if(this.detail.JHZZJDFZC == null){
                    this.detail.JHZZJDFZC  =df
                }
            },
            Subtr(arg1,arg2){
                var r1,r2,m,n;
                try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
                try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
                m=Math.pow(10,Math.max(r1,r2));
                n=(r1>=r2)?r1:r2;
                console.log(n)
                return ((arg1*m-arg2*m)/m);
            },
            sortByKey(array,key,type){
                return array.sort(function(a,b){
                    var x = parseFloat(a[key]);
                    var y = parseFloat(b[key]);
                    if(type == 'desc'){
                        return((x>y)?-1:((x<y)?1:0));
                    }else{
                        return((x<y)?-1:((x>y)?1:0));
                    }
                })
            },
            showMaps(row) {
                this.http.post('/api/Ncgl_flag/getItemById?id='+row.ID,{}).then(res=>{
                    // if(res.status){
                    row.XZFW = res.XZFW
                    row.BaseInfoID = res.BaseInfoID ? res.BaseInfoID : row.ID
                    row.QQID = res.ID!='00000000-0000-0000-0000-000000000000' ? res.ID : row.ID
                    this.dialogTableVisibleData = row;
                    this.dialogTableVisibleMap = true;
                    // }else{
                    //     this.$message.error(res.message)
                    // }
                })
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },

            closeDrawer(){
                this.$refs.detailForm.resetFields()
            },
            closeDrawer2(){

                console.log(this.isLock);
                if(this.isLock == false){
                    this.$refs.detailForm.resetFields()
                    this.showAddModel = false;
                }else{
                    this.showMessage = true
                }
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },

            checkNumFs  (rule, value, callback)  {
                if (value < 0) {
                    callback(new Error('不能为负数'));
                }else{
                    callback();
                }
            },
            checkNum  (rule, value, callback)  {
                console.log(value)
                if (!value) {
                    callback(new Error('请输入数字值'));
                }
                // if (value < 0) {
                //     callback(new Error('不能为负数'));
                // }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                // if (this.newAdd == false){
                //
                //     return false
                // }
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            confirmChoose(){

            },
            addRoad(row){
                console.log(row)
                this.activeName = '0'
                this.buttonLoading = false;
                this.addStatus = true
                this.detail = {
                    XMDM:row.XMDM,
                    Pid:row.Id,
                    XMSZD:row.XMSZD,
                    XMCW:row.XMCW,
                    ZGBM:row.ZGBM,
                    SSTXLY:row.SSTXLY,
                }
                this.showAddModel = true
            },
            //退出编辑
            closeEdit(){
                localStorage.removeItem('fillInfo')
                this.showMessage = false
                this.showEditModel = false
                this.showAddModel = false
                this.detail = {}
            },
            //删除图片
            deleted(row){
                console.log(row)
                this.$confirm('此操作将永久删除此数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.http.post('/api/Plan_disaster/DeleteById?id='+row.Id,{}).then(res=>{
                        if(res.status){
                            this.search()
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }else{
                            this.$message.error(res.message)
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
                console.log(row)
                this.visible = false
            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            changeJstotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.LQHNTLM == null || this.detail.LQHNTLM ==  '' || !reg.test(this.detail.LQHNTLM) ? 0 : parseFloat(this.detail.LQHNTLM)
                var xz7 = this.detail.SNHNTLM == null || this.detail.SNHNTLM ==  '' || !reg.test(this.detail.SNHNTLM) ? 0 : parseFloat(this.detail.SNHNTLM)
                var xz2 = this.detail.EJ == null || this.detail.EJ ==  '' || !reg.test(this.detail.EJ) ? 0 : parseFloat(this.detail.EJ)
                var xz3 = this.detail.SJ == null || this.detail.SJ ==  '' || !reg.test(this.detail.SJ) ? 0 : parseFloat(this.detail.SJ)
                var xz4 = this.detail.SI == null || this.detail.SI ==  '' || !reg.test(this.detail.SI) ? 0 : parseFloat(this.detail.SI)

                this.detail.JSGMGJ = this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz7)
            },
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m + arg2 * m) / m;
            },
            accDec(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m - arg2 * m) / m;
            },
            //附件处理
            dealFj(data){
                // console.log(data)
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }
            },
            dealSaveFj(data){
                console.log(data);
                if(data == null){
                    return ''
                }
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                // console.log( pfwjString.join(';'),456)
                return pfwjString.join(';')
            },
            save(){
                if(this.buttonLoading == true){
                    return
                }
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        console.log(params)
                        this.buttonLoading = true;

                        if(this.addStatus){
                            params.Id='00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_disaster/AddItem',params).then(res=>{
                                this.buttonLoading = false;
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                    this.$refs.detailForm.resetFields()
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }else{
                            this.buttonLoading = false;
                            this.http.post('/api/Plan_disaster/UpdateItem',params).then(res=>{
                                if(res.status){
                                    this.showAddModel = false
                                    this.$message.success(res.message)
                                    this.search()
                                    this.$refs.detailForm.resetFields()
                                    this.addStatus = true
                                }

                            })
                        }
                    }
                });
            },
            checksB(row){
                this.dialogVisibleid = row.Id
                this.dialogVisible123 = true;
            },
            checksBs(){
                if(this.shsbloading == false){
                    return false;
                }
                this.shsbloading = false;
                this.http.post('/api/Plan_filling/SaveFilling?id='+this.dialogVisibleid,{}).then(res=>{
                    this.shsbloading = true;
                    if(res.status){
                        this.showAddModel = false
                        this.$message.success("上报成功")
                        this.search()
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false
                this.$refs.editForm.resetFields()

            },
            getCaption(obj){
                var index=obj.lastIndexOf(".");
                obj=obj.substring(index+1,obj.length);
                return obj;
            },
            openDetailsEdit(row) {
                this.activeName = '0'
                this.buttonLoading = false;
                this.addStatus = false
                this.showAddModel = true
                this.http.post('/api/Plan_disaster/getItemById?id='+row.Id,{}).then(res=>{
                    this.detail = res;
                })
            },
            exportData() {
                var that = this
                this.http.post('/api/Plan_disaster/Plan_disasterTemplateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_disaster' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '增发国债项目情况表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                this.expParamsCity = [];
                var postData = { SZDS: "", SZX: "" };
                var newres = [];
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        // let citys = [
                        //     {
                        //         value: "",
                        //         label: "北大荒集团",
                        //         children:[
                        //             {
                        //                 value: "",
                        //                 label: "建三江管理局",
                        //             },
                        //         ],
                        //     },
                        //     {
                        //         value: "",
                        //         label: "省建设中心",
                        //         children:[],
                        //     },{
                        //         value: "",
                        //         label: "省公路中心",
                        //         children:[],
                        //     },{
                        //         value: "",
                        //         label: "省交投集团",
                        //         children:[],
                        //     }
                        // ];
                        // res = res.concat(citys)
                        // console.log(res);
                        res.map((r) => {
                            r.value = r.label;
                            newres.push({
                                level: r.level, value: r.label, label:r.label
                            })
                            this.expParamsCity.push(['',r.value])
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;
                        this.regions2[0].children = newres;
                        // console.log(this.regions2);
                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http
                    .post(
                        "/api/Plan_disaster/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        console.log(res)
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        this.tableData = res;
                        console.log(this.tableData)
                        // if (res.status == 0) {
                        //     this.total = res.total;
                        // }
                    });
            },
            postData() {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //项目名称
                var query_dw = {
                    Name: "XMCW",
                    Value: this.XMCW,
                    DisplayType: "like",
                };
                //所属市
                var query_szds = {
                    Name: "XMSZD",
                    Value: ssx,
                    DisplayType: "like",
                };

                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "XH",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_szds,
                        query_xmmc,
                        query_dw
                    ]),
                };
                return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.XMCW = ''
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getCompanyList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getCompanyList()

            },
            setNumDefault(nums){
                if(!nums) return 0;
                return parseFloat(nums);
            },
            moneyToNumFiled(money){
                if(money == 0 || !money) return 0;
                return money;

                // var num=money.trim();
                // var ss=num.toString();
                // if(ss.length==0){
                //     return 0.00;
                // }
                // num=new Number(ss.replace(/,/g, ""));
                // return parseFloat(num);
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
</style>
<style lang="less" scoped>
    table{
        border-color: rgba(255,255,255,.5);
    }
    .littleTable{
        /deep/input[readonly] {
            background-color: #fff !important;
            border: 0px solid #ddd !important;
        }
    }
    .editWrap{

        /deep/.el-drawer__body {
            padding: 12px 15px 0px;
            /* padding-bottom: 60px; */
        }

    }
    /deep/ .el-textarea{
        .el-textarea__inner{
            height: auto !important;
            min-height: auto !important;
        }
    }
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }

    .roadFront{
        .jhky{
            /deep/ .el-form-item{
                margin-right: 0px !important;
            }
        }
        .ty{
            /deep/ .el-form-item{
                margin-right: 0px !important;
            }
        }
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /deep/.el-collapse-item__content {
            padding:10px 15px 20px 15px  !important;
        }
        .editModal{
            /deep/.el-input__inner{
                width: 330px;
            }
            .tz{
                /deep/.el-input__inner{
                    width: 280px;
                }
                /deep/.el-form--inline .el-form-item{
                    margin-right: 0px;
                }
            }
            .jhky{
                /deep/.el-input__inner{
                    width: 135px;
                }
            }
            .ty{
                /deep/.el-input__inner{
                    width: 270px;
                }
            }
            .tztop{
                /deep/.el-form-item__label{
                    background: unset;
                }
                /deep/.el-input__inner {
                    width: 260px;
                }
            }
            .littleTable{
                /deep/.el-input__inner {
                    width: 100%;
                    border: none;
                    height: 60px;
                }
            }
            .littleTable2{
                /deep/.el-input__inner {
                    width: 142px;
                    /*margin: 2px 4px;*/
                    border: none;
                    height: 45px;
                }
            }
        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 330px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #f2f8fe;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
        .col{
            /deep/.el-form-item__label{
                background: #F2F8FE;
                color: #78818E;
                line-height: 30px;
                float: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /*/deep/ .el-input--suffix{*/
    /*    width: 330px;*/
    /*}*/
    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error {
        position: relative !important;
        top: auto;
        display: block;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    /*/deep/.el-form-item__error--inline{*/
    /*    top: 14px;*/
    /*    left: 5px;*/
    /*    position: absolute;*/
    /*    background: #fff;*/
    /*    display: inline-block;*/
    /*    margin-left: 10px;*/
    /*}*/
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 4px;
        .dwmc{
            padding-left: 10px;
            text-align: left;
            width: 330px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 40px;
            background: #ffffff;
            border-radius: 4px 0 0 4px;
        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 40px;
            background: #ffffff;
            border-radius: 0px 4px 4px 0;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }

    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        height: 47px;
        background: #F5F7FA;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        height: 47px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 220px;
        height: 47px;
        text-align: center;
        line-height: 36px;
    }
    .cont2{
        height: 47px;
        text-align: center;
        line-height: 36px;
    }
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid #dddddd;
        .cont {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .flexs-m {
        display: flex;
        align-items: initial;
    }
    .title {
        margin-top: 20px;
        background: #f2f8fe;
        display: flex;
        align-content: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        i {
            line-height: 32px;
            margin-right: 12px;
            font-size: 18px;
        }
        span {
            display: block;
            width: 98px;
            height: 32px;
            background: #409eff;
            line-height: 32px;
            font-size: 16px;
            font-family: Segoe UI;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
        }
    }

    .name {
        width: 220px;
        height: 42px;
        background: #fafafa;
        text-align: center;
        line-height: 42px;
        font-weight: 600;
    }
    .value {
        display: flex;
        align-items: center;
        width: calc(100% - 168px);
        padding: 0 10px;
        // height: 42px;
        line-height: 42px;
        font-weight: 400;
        box-sizing: border-box;
        color: #666666;
    }
    .name1 {
        width: 74px;
        line-height: normal;
        label {
            width: 100%;
            padding: 0;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-family: Segoe UI;
            font-weight: 400;
            color: #ffffff;
            i {
                font-style: normal !important;
            }
        }
    }
    .fileWrap{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .value1 {
        display: flex;
        align-items: center;
        width: calc(100% - 168px);
        display: flex;
        height: auto;
        label {
            display: inline-block;
            vertical-align: baseline;
        }
        .files {
            display: block;
            // border: 1px solid #b3d8ff;
            // height: 36px;
            // line-height: 36px;
            border-radius: 4px;
            position: relative;
            top: 2px;
            .wj {
                margin-bottom: 5px;
                border: 1px solid #b3d8ff;
                height: 30px;
                vertical-align: middle;
                line-height: 30px;
                display: inline-block;
                margin-left: 10px;
                border-radius: 4px;
                padding: 0 5px;
                position: relative;
                top: -2px;
            }
        }
    }
    .name3 {
        background: none;
    }
    .file{
        margin-right: 10px;
        display:flex;
        align-items: center;
        margin-top: 0px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 20px;
            width: 330px;
            background: #F2F8FE;
            min-height: 780px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
    .newform{
        /deep/.el-input__inner{
            width: 100% !important;
            padding: 0 5px !important;
        }
        /deep/.el-form-item__error{
            position: absolute !important;
            top: -2px;
        }
        /deep/.el-select{

            /deep/.el-input--suffix{
                width: 100% !important;
                /deep/.el-input__inner{
                    text-align: right;
                    // color: #C0C4CC;
                    font-size: 14px;
                    padding-right: 30px !important;

                }

            }
            /deep/.is-disabled{

                /deep/.el-input__inner{
                    background-color: #F5F7FA !important;

                }
            }
        }
    }
    .newre{
        display: inline-block;
        background: #409eff;
        padding: 0px 5px;
        border-radius: 50px;
        margin: 0 9px;
        position: relative;
        top: 1px;
        /deep/.el-input__suffix{
            right: 9px;
            top: -1px;
        }
        /deep/.el-tag{
            background: none !important;
        }
        /deep/.el-tag:not(:first-child){
            display: none;
        }
        /deep/.el-button--mini{
            position: relative;
            top: -1px;
            padding: 3px 0 !important;
        }
        /deep/.el-input__inner{
            width: 120px !important;
            height: 20px !important;
            border-radius: 50px;
        }
        /deep/.el-input--suffix{
            width: 100% !important;
            .el-input__icon{
                line-height: 30px;
            }
        }
    }
    .colors{
        /deep/.el-input__inner{
            color: #f00;
        }
    }
    /deep/input [type="number"]{
        -moz-appearance:textfield;
    }
    /deep/input::-webkit-outer-spin-button{
        -webkit-appearance: none !important;
    }
    /deep/input::-webkit-inner-spin-button{
        -webkit-appearance: none !important;
    }
    .inputcenter{
        /deep/.el-input__inner{
            text-align: center;
        }
    }
    .inputright{
        /deep/.el-input__inner{
            text-align: right;
        }
    }
    /deep/.is-disabled{
        .el-input__inner{
            background-color: #F5F7FA !important;
        }

    }
    .dis{
        background-color: #F5F7FA !important;
    }
    .xxh{
        display: inline-block;
        width: 26.5%;
        /deep/.el-input__inner{

            border: 0;
            border-bottom: 1px #dcdfe6 solid;
            width: 100% !important;
        }
    }
    .fale{
        padding:3px 5px;
        background: #F5F7FA;
        color: #c0c4cc;text-align: right
    }
</style>
